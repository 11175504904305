import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import AlertBanner from "../../components/AlertBanner";
import Space from "../../components/Space";
import Button from "../../components/Button";
import { get } from "../../api";
import Label from "../../components/Label";
import TextInput from "../../components/TextInput";
import SignupFrame from "../../components/SignupFrame";
import InputRow from "../../components/InputRow";
import styles from "./SignUp.module.scss";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import { HOST } from "../../constants/api";
import validate from "../../helpers/validate";
import Image from "../../components/Image";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { useConfirm } from "material-ui-confirm";
import { set } from "date-fns";
export default function SignUp() {
  const [formState, { text, email, tel, number }] = useFormState();
  const [step, setStep] = useState(0);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [agreeTos, setAgreeTos] = useState(false);
  const [deviceToAdd, setDeviceToAdd] = useState("");
  const [addedDevices, setAddedDevices] = useState([]);
  const [deviceLookup, setDeviceLookup] = useState(false);
  const [phoneValidationError, setphoneValidationError] = useState(undefined);
  const [cost, setCost] = useState({
    tier: 0,
    devices: 0,
    total: 0,
    sum: 0,
  });
  const confirm = useConfirm();
  const [address, setAddress] = useState("");
  const [isCheckingDomain, setIsCheckingDomain] = useState(false);
  function MapResults(result) {
    setAddress(result.formatted_address);
    result.address_components[0] &&
      formState.setField(
        "shipping_address",
        result.address_components[0].long_name +
          " " +
          result.address_components[1].long_name
      );
    result.address_components[2] &&
      formState.setField(
        "shipping_city",
        result.address_components[2].long_name
      );

    result.address_components[4] &&
      formState.setField(
        "shipping_state",
        result.address_components[4].long_name
      );
    result.address_components[5] &&
      formState.setField(
        "shipping_country",
        result.address_components[5].long_name
      );
    if (result.address_components[6]) {
      //check if address_components[6] is a number
      if (isNaN(result.address_components[6].long_name)) {
        //check if address_components[7] is a number
        if (result.address_components[7]) {
          if (!isNaN(result.address_components[7].long_name)) {
            formState.setField(
              "shipping_zip",
              result.address_components[7].long_name
            );
          } else {
            formState.setField("shipping_zip", "no-zip-code");
          }
        } else {
          formState.setField("shipping_zip", "no-zip-code");
        }
      } else {
        formState.setField(
          "shipping_zip",
          result.address_components[6].long_name
        );
      }
    }
  }

  function handleChange(address) {
    setAddress(address);
  }

  function handleSelect(address) {
    geocodeByAddress(address).then((results) => MapResults(results[0]));
  }

  var approvedDevices = [];
  async function submit(e) {
    e.preventDefault();
    setSaving(true);

    const formData = new FormData();
    Object.keys(formState.values).forEach((key) =>
      formData.append(key, formState.values[key])
    );
    formData.append("deviceTotal", cost.total);
    formData.append("tier", cost.tier);
    formData.append("numberofdevices", cost.devices);

    for (var i = 0; i < addedDevices.length; i++) {
      formData.append("devices_serials", addedDevices[i].serial_number);
    }
    try {
      await fetch(HOST + "/customer_request", {
        method: "POST",
        body: formData,
      });
      setSaving(false);
      setSuccess(true);
    } catch (e) {
      setError("Something went wrong. Try again later.");
    }
  }

  function renderSuccess() {
    return (
      <SignupFrame>
        <Space size="m">
          <p>
            Your request has been received and we will be in touch soon. Thanks!
          </p>
        </Space>
        <Link to="/">
          <Button>Back to home</Button>
        </Link>
      </SignupFrame>
    );
  }
  const valid = validate(formState, {
    required: [
      "name",
      "lastname",
      "company",
      "email",
      "phone",
      "shipping_address",
      "shipping_city",
      "shipping_zip",
      "shipping_state",
      "shipping_country",
    ], //, 'creditCardNr', 'nameOnCard', 'billingAddress', 'EXP1', 'EXP2', 'EXP2', 'CVV']
  });

  function isEmailValidated(nr) {
    switch (nr) {
      case 0:
        return "Enter emaill"; //formState.errors.email;
    }
  }
  function recalculate(value) {
    if (value >= 1) {
      setCost({
        devices: value,
        total: 0,
        tier: cost.tier,
        sum: cost.tier * 12 * value, // + cost.total
      });
    } else {
      setCost({
        devices: 1,
        tier: cost.tier,
        total: 550,
        sum: cost.tier * 12 * 1,
      });
    }
  }

  function obfuscateSerial(serial) {
    return serial.toString().replace(/^.{10}/g, "**********");
  }
  function generateInputSerials() {
    return addedDevices.reverse().map((dev) => (
      <>
        <TextInput
          icon={"done"}
          value={dev.serial_number && obfuscateSerial(dev.serial_number)}
          disabled
        ></TextInput>
      </>
    ));
  }

  async function checkDevice() {
    setDeviceLookup(true);

    const res = await get(`/devices/subscription_check/${deviceToAdd}`);
    if (res && res.id !== 0) {
      var newArray = addedDevices.slice();
      var alreadyInList = addedDevices.find((x) => x.id === res.id);
      if (alreadyInList === undefined) {
        newArray.push(res);
        recalculate(cost.devices + 1);
        setAddedDevices(newArray);
        setDeviceToAdd("");
      } else {
        alert("Serial number is already added to your list");
      }
    } else {
      alert(
        "The serial number you entered is invalid. Enter the last 5 digits of the serial number located underneath the box"
      );
    }
    setDeviceLookup(false);
  }

  function updateEmail(val) {
    if (val != undefined && val != "") {
      if (isValidPhoneNumber(val)) {
        formState.setField("phone", val);
        setphoneValidationError(false);
      } else {
        setphoneValidationError("Enter a valid phone number");
      }
    } else {
      setphoneValidationError("Fill out this field");
    }
  }

  async function proceedToNextStep(e) {
    e.preventDefault();
    if (step === 0) {
      setIsCheckingDomain(true);
      const res = await get(`/customers/exists`, {
        email: formState.values.email,
      });

      if (res === true) {
        confirm({
          description:
            "This email is already in use. Please use a different email address.",
          title: null,
          dialogProps: { fullScreen: false },
          cancellationButtonProps: { style: { display: "none" } },
          confirmationButtonProps: { color: "primary", disableRipple: true },
        })
          .then(() => {})
          .catch(() => {
            /* ... */
          });
      } else {
        const domainCheck = await get(`/customers/domain`, {
          email: formState.values.email,
        });
        if (domainCheck === true) {
          confirm({
            description:
              "This domain is already in use. Please contact your system administrator.",
            title: null,
            dialogProps: { fullScreen: false },
            cancellationButtonProps: { style: { display: "none" } },
            confirmationButtonProps: { color: "primary", disableRipple: true },
          })
            .then(() => {})
            .catch(() => {
              /* ... */
            });
        } else {
          setStep(3);
        }
      }
      setIsCheckingDomain(false);
    }
  }

  function _renderFooter() {
    switch (step) {
      case 0:
        return (
          <div className={styles.footer}>
            <Button
              theme="primary"
              disabled={!valid || phoneValidationError || isCheckingDomain}
              isLoading={isCheckingDomain}
              onClick={(e) => {
                e.preventDefault();
                proceedToNextStep(e);
              }}
            >
              Next
            </Button>
          </div>
        );

      case 1:
        return (
          <div className={styles.footer}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setStep(0);
              }}
            >
              Back
            </Button>
            <Button
              theme="primary"
              disabled={cost.tier === 0}
              onClick={(e) => {
                e.preventDefault();
                setStep(step + 1);
              }}
            >
              Next
            </Button>
          </div>
        );
      case 2:
        return (
          <div className={styles.footer}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setStep(step - 1);
              }}
            >
              Back
            </Button>
            <Button
              theme="primary"
              disabled={cost.devices === 0}
              onClick={(e) => {
                e.preventDefault();
                setStep(step + 1);
              }}
            >
              Next
            </Button>
          </div>
        );
      case 3:
        return (
          <form onSubmit={submit}>
            <div className={styles.footer}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setStep(0);
                }}
              >
                Back
              </Button>

              <Button
                type="submit"
                theme="primary"
                isLoading={saving}
                disabled={!agreeTos}
              >
                Create account
              </Button>
            </div>
          </form>
        );
    }
  }
  function renderStep(step) {
    switch (step) {
      case 0:
        return (
          <>
            {/*<p className={styles.header}>
          Account details
      </p> */}
            <InputRow>
              <Space size="s">
                <Label text="First name" required>
                  <TextInput
                    {...text("name")}
                    required
                    error={formState.touched.name && formState.errors.name}
                  />
                </Label>
              </Space>
              <Space size="s">
                <Label text="Last name" required>
                  <TextInput
                    {...text("lastname")}
                    required
                    error={
                      formState.touched.lastname && formState.errors.lastname
                    }
                  />
                </Label>
              </Space>
            </InputRow>
            <InputRow>
              <Label text="Company" required>
                <TextInput
                  {...text("company")}
                  required
                  error={formState.touched.company && formState.errors.company}
                />
              </Label>
            </InputRow>
            <InputRow>
              <Space size="s">
                <Label text="Email" required>
                  <TextInput
                    {...email("email")}
                    required
                    /*placeholder={'Email'}*/ error={
                      formState.touched.email && formState.errors.email
                    }
                  />
                </Label>
              </Space>
              <Space size="s">
                <div>
                  <span style={{ display: "block", paddingBottom: 14 }}>
                    Phone <p className={styles.red}>*</p>
                  </span>
                  <PhoneInput
                    defaultCountry="US"
                    countryCallingCodeEditable={false}
                    required
                    international
                    error={phoneValidationError}
                    flags={flags}
                    inputComponent={TextInput}
                    onChange={(val) => updateEmail(val)}
                    value={
                      formState.values.phone
                        ? formState.values.phone
                        : undefined
                    }
                  />
                </div>
              </Space>
            </InputRow>

            <InputRow>
              <Space size="s">
                <div>
                  <span style={{ display: "block", paddingBottom: 14 }}>
                    Address <p className={styles.red}>*</p> (PO Box not
                    accepted)
                  </span>
                </div>

                <PlacesAutocomplete
                  value={address}
                  //error={formState.touched.address && (isEmailValidated(0))}
                  onChange={handleChange}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          //placeholder: 'Enter address',
                          className: styles.autocomplete,
                        })}
                      />
                      <div className={styles.autocompleteDiv}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? styles.autocompleteSuggestionActive
                            : styles.autocompleteSuggestion;

                          const style = suggestion.active
                            ? { backgroundColor: "#eeeeee", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Space>
            </InputRow>

            {/*<InputRow>
          <Label text="Distributor name">
            <TextInput {...text("distributor")} required placeholder={'If no distributor type "NONE"'} />
          </Label>
                  </InputRow>*/}
          </>
        );
      case 1:
        return (
          <>
            {/* p className={styles.header}>
          Select the Tier
              </p>*/}
            <Space size="s" />
            <InputRow>
              <div className={styles.columns}>
                <div className={cost.tier === 15 && styles.selected}>
                  <ul className={styles.price}>
                    <li className={styles.header}>Tier 1</li>
                    <li className={styles.grey}>$ 15 / month</li>
                    <li>5 Users</li>
                    <li>$ 1.50 / month for each additional user</li>
                    <li>Basic Reporting</li>
                    <li>Email Support</li>
                    <li>
                      <br></br>
                    </li>
                    <li className={styles.grey}>
                      <Button
                        className={styles.button}
                        onClick={(e) => {
                          e.preventDefault();
                          setCost({
                            tier: 15,
                            total: 0,
                            sum: 15 * 12 * cost.devices,
                            devices: cost.devices,
                          });
                        }}
                      >
                        Select
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.columns}>
                <div className={cost.tier === 20 && styles.selected}>
                  <ul className={styles.price}>
                    <li className={styles.header}>Tier 2</li>
                    <li className={styles.grey}>$ 20 / month</li>
                    <li>10 Users</li>
                    <li>$ 1.00 / month for each additional user</li>
                    <li>Enhanced Reporting</li>
                    <li>Email Support</li>
                    <li>Billable Voice Support</li>
                    <li className={styles.grey}>
                      <Button
                        className={styles.button}
                        onClick={(e) => {
                          e.preventDefault();
                          setCost({
                            tier: 20,
                            total: 0,
                            sum: 20 * 12 * cost.devices,
                            devices: cost.devices,
                          });
                        }}
                      >
                        Select
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.columns}>
                <div className={cost.tier === 25 && styles.selected}>
                  <ul className={styles.price}>
                    <li className={styles.header}>Tier 3</li>
                    <li className={styles.grey}>$ 25 / month</li>
                    <li>20 Users</li>
                    <li>$ .75 / month for each additional user</li>
                    <li>Advanced Reporting</li>
                    <li>Email Support</li>
                    <li>Voice Support</li>

                    <li className={styles.grey}>
                      <Button
                        className={styles.button}
                        onClick={(e) => {
                          e.preventDefault();
                          setCost({
                            tier: 25,
                            total: 0,
                            sum: 25 * 12 * cost.devices,
                            devices: cost.devices,
                          });
                        }}
                      >
                        Select
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </InputRow>
          </>
        );
      case 2:
        return (
          <>
            {/*<p className={styles.header}>
          Sentinel devices
      </p> */}
            <Space size="s" />
            <Space size="s">
              <div className={styles.bannerImage}>
                <Image url={"/img/sentinelBox.png"} token={false} zoom={true} />
              </div>
            </Space>
            <Space size="l" />
            <InputRow>
              <Space size="s">
                <Label text="Quantity">
                  <TextInput
                    {...number("numberOfUnits")}
                    required
                    value={cost.devices}
                    onChange={(e) => recalculate(e.target.value)}
                    //error={formState.touched && cost.devices <= 0 && "Select at least 1 device"}
                    disabled
                  />
                </Label>
              </Space>
              <Space>
                <Label text="Last 5 digits of serial number">
                  <TextInput
                    value={deviceToAdd}
                    type="number"
                    spinHide={true}
                    pattern="[0-9]"
                    onChange={(e) =>
                      e.target.value.length <= 5 &&
                      setDeviceToAdd(e.target.value)
                    }
                    placeholder=""
                  ></TextInput>
                </Label>
              </Space>
              {/* <Space>
            <div className={styles.addDeviceButton} >
              <Button
                onClick={(e) => { e.preventDefault(); checkDevice() }}
                disabled={deviceToAdd.length < 5 || deviceLookup}
                isLoading={deviceLookup}
              >Add device</Button>
            </div>
    </Space> */}
            </InputRow>
            <div className={styles.devicesList}>{generateInputSerials()}</div>
          </>
        );
      case 3:
        return (
          <>
            <p className={styles.header}>Order Summary</p>
            <Space size="xxs" />
            <div className={styles.summary}>
              <div className={styles.row}>
                <div className={styles.left}>Company</div>
                <div className={styles.right}>
                  {" "}
                  <b>{formState.values.company}</b>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.left}>Name</div>
                <div className={styles.right}>
                  {" "}
                  <b>
                    {formState.values.name} {formState.values.lastname}
                  </b>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.left}>Address</div>
                <div className={styles.right}>
                  {" "}
                  <b>
                    {formState.values.shipping_address},{" "}
                    {formState.values.shipping_city},{" "}
                    {formState.values.shipping_zip},{" "}
                    {formState.values.shipping_state},{" "}
                    {formState.values.shipping_country}{" "}
                  </b>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.left}>Phone</div>
                <div className={styles.right}>
                  {" "}
                  <b>{formState.values.phone} </b>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.left}>Email</div>
                <div className={styles.right}>
                  {" "}
                  <b>{formState.values.email}</b>
                </div>
              </div>
              {/* <div className={styles.row}>
            <div className={styles.left}>Distributor</div><div className={styles.right}>  <b>{formState.values.distributor}</b></div>
          </div>
*/}
            </div>

            {/*<div className={styles.summary}>
          <div className={styles.row}>
            <div className={styles.left}>Quantity</div><div className={styles.right}> <b>{cost.devices}</b></div>
          </div>
          <div className={styles.row_underline}>
            <div className={styles.left}>Yearly total</div><div className={styles.right}>  {cost.sum != 0 ? (<b>$ {cost.sum}</b>) : (<>Select the Tier</>)}</div>
          </div>
        </div>
      */}
            <InputRow>
              <div className={styles.centered}>
                <CheckboxWithLabel
                  fullwidth={true}
                  checked={agreeTos}
                  onChange={(e) => setAgreeTos(e.target.checked)}
                  required
                  label={
                    <>
                      I agree to the{" "}
                      <Link
                        to="/terms-of-service"
                        target="_blank"
                        className={styles.tos}
                      >
                        <u>Terms of Service</u>
                      </Link>
                      .
                    </>
                  }
                />
              </div>
            </InputRow>
          </>
        );
    }
  }

  function renderForm() {
    return (
      <SignupFrame>
        {error && (
          <Space size="l">
            <AlertBanner theme="danger">{error}</AlertBanner>
          </Space>
        )}
        <div className={styles.signUpForm}>{renderStep(step)}</div>
        {_renderFooter()}
      </SignupFrame>
    );
  }

  return success ? renderSuccess() : renderForm();
}
