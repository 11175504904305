import React, { useContext, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";

import { post } from "../../api";
import { AuthContext } from "../../contexts/Auth";
import LoadingFrame from "../../components/LoadingFrame";
import AlertBanner from "../../components/AlertBanner";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import Button from "../../components/Button";
import Label from "../../components/Label";
import Space from "../../components/Space";
import TextInput from "../../components/TextInput";
import LoginFrame from "../../components/LoginFrame";

import styles from "./Register.module.scss";

export default function Register({ history, location: { search } }) {
  const queryParams = queryString.parse(search);

  const { state } = useContext(AuthContext);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [agreeTos, setAgreeTos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (queryParams.tokenExpiry) {
      const decodedExpiration = atob(queryParams.tokenExpiry);
      const expiration = moment
        .utc(decodedExpiration, "MM/DD/YYYY HH:mm:ss A")
        .local();
      const now = moment();

      if (now.isAfter(expiration)) {
        setRedirect(`/expired?token=${queryParams.token}&type=activate`);
        setIsLoading(false);
        return;
      }
    }
    if (state.isAuthenticated || !queryParams.token) {
      setRedirect("/");
    }
    setIsLoading(false);
  }, []);

  function _validateForm() {
    const blank = !password1 && !password2;
    const equal = password1 === password2;

    return blank ? true : equal && agreeTos ? false : true;
  }

  async function _register(e) {
    e.preventDefault();
    setLoading(true);

    const res = await post("/users/resetPassword", {
      token: queryParams.token,
      password: password1,
    });

    res.ok ? setRedirect(`/log-in?activated=true`) : setError(true);
  }

  function _renderError() {
    return (
      <Space size="l">
        <AlertBanner theme="danger">
          Something went wrong. Confirm you entered an identical password in
          both fields and try again.
        </AlertBanner>
      </Space>
    );
  }

  function _renderForm() {
    return (
      <LoginFrame
        heading="Welcome!"
        subHeading="Create a password to complete your registration."
      >
        {error && _renderError()}

        <form onSubmit={_register}>
          <Space size="l">
            <div className={styles.inputWrap}>
              <Space size="s">
                <Label text="Password">
                  <TextInput
                    type="password"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                  />
                </Label>
              </Space>
              <Label text="Confirm password">
                <TextInput
                  type="password"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                />
              </Label>
            </div>
          </Space>

          <Space size="xl">
            <CheckboxWithLabel
              fullwidth={true}
              checked={agreeTos}
              onChange={(e) => setAgreeTos(e.target.checked)}
              label={
                <>
                  I agree to the{" "}
                  <Link
                    to="/terms-of-service"
                    target="_blank"
                    className={styles.tos}
                  >
                    Terms of Service
                  </Link>
                  .
                </>
              }
            />
          </Space>

          <Space size="l">
            <Button
              type="submit"
              theme="primary"
              isLoading={loading}
              disabled={_validateForm()}
            >
              Create account
            </Button>
          </Space>
        </form>
      </LoginFrame>
    );
  }

  function _renderRedirect() {
    return <Redirect from="register" to={redirect} nothrow />;
  }

  return isLoading ? (
    <LoadingFrame />
  ) : redirect ? (
    _renderRedirect()
  ) : (
    _renderForm()
  );
}
